import {mapMutations, mapGetters, mapActions} from "vuex";

export default {
    name: "constructor",
    props: {
        item: {
            type: Object,
            default: () => {
            }
        },

    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            constructorsData: 'constructors/constructors',
            globalRequestsFlag: 'system/globalRequestsFlag',
            page: 'constructors/constructorsData'
        }),
    },
    created() {
        this.getConstructors()
    },
    watch: {
        '$route': function () {
            let route = this.constructorsData.find(el => el.key === this.$route.name)
            document.title = `${route.name}`
        }

    },
    methods: {
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        ...mapActions({
            constructors: 'constructors/GET_CONSTRUCTORS',
            constructorPage: 'constructors/GET_CONSTRUCTORS_PAGE',
        }),
        getConstructors() {
            this.constructorPage()
            this.constructors().then(() => {
                let route = this.constructorsData.find(el => el.key === this.$route.name)
                document.title = `${route.name}`
            })
            this.setRequestFlag(true)
        }
    },
}
